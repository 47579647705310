import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="terms-of-use" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Terms of Use</h1>
      <p><strong>Last Updated: Feb 24, 2025</strong></p>

      <p>
        Thank you for your interest in Gravy ("we," "us," or "our"), our website located at <a href="https://www.gravyapp.com" target="_blank" rel="noopener noreferrer">www.gravyapp.com</a> (the “Site”), our mobile applications (the “Mobile Apps”), and any associated websites and subdomains, applications, and services (collectively, the “Service”). This Terms of Use Agreement (“Terms”) is a legally binding contract between you and Gravy.
      </p>
      <p>
        By registering for an account, downloading or installing the App, or otherwise accessing or using the Service, or any portion thereof, you acknowledge and agree that you have read, understand, and agree to be bound by these Terms.
      </p>
      <p>
        You represent and warrant that you have the right, authority, and capacity to enter into these Terms. If the individual entering into this agreement or otherwise accessing or using the Service is doing so on behalf of, or within his or her capacity as a representative, agent, or employee of an entity, such individual and such entity agree that: (i) the terms “you” and “your” as used herein apply to such entity and such individual; and (ii) the individual entering into these Terms has the power, right, authority, and capacity to enter into these Terms on behalf of such entity. If you do not agree with all of the provisions of these Terms, you may not access or use the Service.
      </p>
      <p>
        These Terms are subject to occasional revision. Please regularly check the Site to view the most recent terms.
      </p>
      <p>
        <strong>
          PLEASE READ THESE TERMS OF USE CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THESE TERMS OF USE CONTAIN A MANDATORY INDIVIDUAL ARBITRATION PROVISION IN SECTION 12.2 (THE “ARBITRATION AGREEMENT”) AND A CLASS ACTION/JURY TRIAL WAIVER PROVISION IN SECTIONS 12.4 AND 12.5 (THE “CLASS ACTION/JURY TRIAL WAIVER”) THAT REQUIRE, UNLESS YOU OPT OUT PURSUANT TO THE INSTRUCTIONS IN THE ARBITRATION AGREEMENT, THE EXCLUSIVE USE OF FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES BETWEEN YOU AND US. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY WAIVE YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS, AS WELL AS YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL, OR REPRESENTATIVE ACTION OR PROCEEDING.
        </strong>
      </p>

      <h2>Eligibility</h2>
      <p>
        This is a contract between you and Gravy. You must read and agree to these Terms before using the Service. If you do not agree, you may not use the Service. You may use the Service only if you can form a binding contract with Gravy, and only in compliance with these Terms and all applicable local, state, national, and international laws, rules, and regulations.
      </p>
      <p>
        The Service is not available to any users previously removed from the Service by Gravy. The Service is only available to residents of the United States and is only intended for use in the United States. Any access to, or use of, the Service by non-U.S. residents is strictly prohibited and in violation of these Terms. Gravy makes no representations that the Service is appropriate or available for use in other locations. By using the Service, you represent that you are a U.S. resident.
      </p>
      <p>
        Any use or access to the Service by anyone under 13 is strictly prohibited and in violation of these Terms.
      </p>

      <h2>1. Accounts</h2>
      <h3>1.1. Account Creation</h3>
      <p>
        In order to use most features of the Service, you must register for an account (“Account”). You promise that: (a) all required registration information you submit in connection with your registration is truthful and accurate; and (b) you will maintain the accuracy of such information.
      </p>
      <h3>1.2. Account Responsibilities</h3>
      <p>
        You are responsible for all activities that occur under your Account. You agree to immediately notify Gravy of any unauthorized use or suspected unauthorized use of your Account or any other breach of security. You may not share your Account with anyone. You agree that you shall not have more than one Account at any given time. You agree not to create an Account or use the Service if you have been previously removed by Gravy. If you provide any information that is untrue, inaccurate, not current, or incomplete—or if Gravy has reasonable grounds to suspect that any information you provide is untrue, inaccurate, not current, or incomplete—Gravy has the right to suspend or terminate your Account and refuse any and all current or future use of the Service (or any portion thereof). You agree not to create an Account using a false identity or information, or on behalf of someone other than yourself.
      </p>
      <h3>1.3. Your Account</h3>
      <p>
        You agree that you shall have no ownership or other property interest in your Account, and you further acknowledge and agree that all rights in and to your Account are and shall forever be owned by Gravy.
      </p>
      <h3>1.4. Linking Your Payment Credential</h3>
      <p>
        (a) In order to receive Gravy Rewards (as defined below) from us for qualifying purchases of goods or services, you must link at least one eligible bank account or debit/credit card (a “Payment Credential”) to your Account. Please note that not all bank accounts and debit/credit cards are eligible to be linked to your Account. All determinations as to whether an account or card is eligible to be a Payment Credential are at our sole discretion. For example, certain Visa, Mastercard, and American Express cards are not eligible to be linked to your Account, including, but not limited to, prepaid cards, corporate cards, purchasing cards, store cards, government-administered prepaid cards, healthcare cards, and cards that are not processed through the respective payment systems. In order to be eligible as a Payment Credential, it must be issued or maintained by a U.S. bank. We use Plaid Technologies, Inc. (“Plaid”) to gather users’ data from financial institutions. By using the Services, you grant us and Plaid the right, power, and authority to act on your behalf to access and transmit your personal and financial information from the relevant financial institution.
      </p>
      <p>
        (b) You may opt out of transaction monitoring on the Payment Credential(s) you have registered by removing your Payment Credential(s) in the App.
      </p>
      <p>
        Note that opting out of the Service or unlinking your account does not delete historical data collected by Gravy from those accounts. To delete this information, please submit a deletion request by contacting us at <a href="mailto:help@gravyrewards.com">help@gravyrewards.com</a>. We may not be able to delete your information in all circumstances.
      </p>
      <p>
        (c) For more information on how we use your Payment Credential transaction information, please refer to our Privacy Policy.
      </p>
      <h3>1.5. Referrals</h3>
      <p>
        We may offer bonuses (as defined below) when someone you refer becomes a user of the Service (a “Referral Program”). Any such Referral Program may be subject to additional terms and conditions set forth in the offer or App, at Gravy's sole discretion.
      </p>
      <p>
        You agree that you have obtained all necessary permissions and consents from an individual before referring that individual to Gravy and will inform the individual: (a) that we are not the sender or initiator of any messages or communications that you send to that individual relating to the Referral Program; (b) that you are not acting as our agent in sending any such messages or communications; and (c) that you may be receiving a referral bonus if the individual becomes a new user of Gravy. We do not directly send any referral codes to individuals.
      </p>
      <p>
        Current or inactive former users of the Service cannot qualify as new users for purposes of a Referral Program, and any attempt to create referrals to non-existent individuals or to multiple accounts for the same individual will be void and are in violation of these Terms. We reserve the right to void or take any other action we deem appropriate with respect to referrals.
      </p>

      <h2>2. Rewards</h2>
      <h3>2.1. Gravy Rewards Program</h3>
      <p>
        The Gravy Rewards Program allows you to earn rewards (“Rewards”) from everyday purchases.
      </p>
      <p>
        The Gravy Rewards Program includes, but is not limited to:
      </p>
      <ul>
        <li>Shopping Rewards. Subject to these Terms, we may automatically provide Rewards to you when you purchase qualifying goods or services with your Payment Credential.</li>
      </ul>
      <p>
        Gravy establishes the terms and conditions for each qualifying purchase or action and determines whether you have satisfied such terms and conditions to receive Rewards. These terms and conditions are set forth on the Site and the App and may be updated, modified, suspended, or canceled by Gravy at any time in its sole discretion.
      </p>
      <p>
        Accordingly, you authorize Gravy as your agent to access your Linked Retailers to pull receipts of your purchases. You represent and agree that for all Linked Retailers, you are an authorized user of that account and that you have the authority to provide Gravy access to that account as your agent. Gravy stores those login credentials on your device and only in our systems with your explicit consent. Gravy is not responsible for the security of your mobile device.
      </p>
      <p>
        Gravy reserves the right to change or limit the Rewards available in the App and the frequency in which they’re available; restrict your ability to participate in certain Rewards; change or limit the type of Rewards you can receive for a given action, including, without limitation, the right to correct any inadvertent error in the amount of Reward awarded and to claw-back any unearned or disqualified Rewards added to your Account. All such changes or limitations shall apply to all past, present, or future Rewards, unless otherwise expressly stated.
      </p>

      <h3>2.2. Withdrawing Rewards</h3>
      <p>
        Subject to these Terms and our approval, you may request that we withdraw Rewards from your Account. Rewards may only be withdrawn from your Account through the Mobile App. The balance of Rewards in your Account must be at least $0.01 in order to redeem Rewards, subject to the withdrawal rate specified in the App at the time of withdrawal (which we may change at any time in our sole discretion). When requesting a withdrawal, you will select a preferred withdrawal method ("Redemption Method"). The withdrawal will be processed after we receive and approve your redemption request.
      </p>
      <p>
        By withdrawing Rewards from your Account, you authorize us to electronically credit your withdrawal method for the applicable withdrawal amount. This authorization will remain in full force and effect until the termination of your Account.
      </p>
      <p>
        Certain limits may apply to your withdrawal of Rewards. There is no automatic withdrawal of Rewards. Rewards do not accrue interest.
      </p>
      <p>
        Without limiting any of the other conditions of these Terms, we may modify the redemption terms for Rewards at any time in our sole discretion.
      </p>

      <h3>2.3. Expiration of Rewards</h3>
      <p>
        Your unredeemed Rewards will expire and will no longer be redeemable after seven hundred thirty (730) days of inactivity, which is defined as consecutive days where you do not perform any Reward earning action or redeem any Rewards. After seven hundred twenty-three (723) days of inactivity, Gravy will provide you final notice by sending an email or text message to the email address or phone number provided when you created your account that your unredeemed Rewards will expire after another seven (7) days of inactivity.
      </p>
      <p>
        Prior to your Rewards expiration date, you may prevent your unredeemed Rewards from expiring by either performing a Reward earning action or redeeming your Rewards. Expired Rewards cannot be recovered.
      </p>

      <h2>3. Access to the Service</h2>
      <h3>3.1. Access and Use</h3>
      <p>
        Subject to these Terms, Gravy grants you a non-exclusive, revocable, limited, non-transferable, non-assignable, and “as is” right to use and access the features and functionality of the Service solely for your own personal, non-commercial use, subject to any use limitations.
      </p>
      <h3>3.2. Application License</h3>
      <p>
        Subject to your compliance with these Terms, including without limitation Sections 5.2 and 5.3 below, Gravy grants you a limited non-exclusive, non-transferable, non-sublicensable, revocable license to download, install, and use a copy of the App on each mobile device or computer that you own or control and to run such copy solely for your own personal, non-commercial purposes.
      </p>
      <h3>3.3. Modification</h3>
      <p>
        We may modify, update, suspend, or discontinue the Service (in whole or in part) at any time with or without notice to you. You agree that Gravy is not liable to you or to any third party for any modification, update, suspension, or discontinuation of the Service. You may need to update third-party software from time to time in order to use the Service.
      </p>
      <h3>3.4. Location Data</h3>
      <p>
        Some features of the Service enable us to tailor your experience based on your location. If you decline to provide location information, or if we cannot verify your location, you will be unable to utilize some features of the Service.
      </p>
      <h3>3.6. App Usage Data</h3>
      <p>
        Some features of the Service are designed to collect and share information related to your usage of mobile apps (“App Usage Data”). In order for you to access these features, the Service collects information about your app usage, including the time spent in apps installed on your device, the app name, and the date and time of use. If you decline to provide App Usage Data, you will be unable to utilize some features of the Service.
      </p>
      <h3>3.7. Biometric Information</h3>
      <p>
        In order to verify your identity in connection with a request to cash out, or to confirm that you have not created multiple accounts, we may request access to your mobile camera and require you to take a real-time selfie. We use third-party technology on Gravy’s servers to analyze the selfie and generate facial biometric information. If you decline to provide biometric information, you will be unable to utilize some features of the Service, including redeeming Rewards.
      </p>

      <h2>Text Messages and Other Communications</h2>
      <p>
        Here’s where we describe your consent to receive texts from us and your representations regarding texts we send to others relating to your payments. You also consent to us sending messages to your email. You can opt out of communications in ways that are specific to that communication method, such as by unsubscribing from emails, or replying to text messages with ‘STOP’.
        By providing us with a mobile number, either when you sign up for an Account or update the contact information associated with your Account, you consent to receiving text (SMS) messages and push notifications from us. Such communications may include, but are not limited to requests for secondary authentication, receipts, reminders, notifications regarding updates to your account or account support, and marketing or promotional communications. You acknowledge that you are not required to consent to receive promotional texts or calls as a condition of using the Services. Call and text message communications may be generated by automatic telephone dialing systems. Standard message and data rates applied by your cell phone carrier may apply to the text messages we send you.
        If you provide us with the mobile number of any other person or entity when initiating or requesting a payment through the Service, you represent and warrant to us that the person or entity has consented to receive text messages from us related to that payment. Standard text messaging rates may apply based on your plan with your mobile carrier.
        You may opt-out of receiving promotional email communications we send to you by following the unsubscribe options on such emails. You may opt out of any promotional phone calls by informing the caller that you would not like to receive future promotional calls. You may opt-out of text messages from the Company by replying STOP or by changing your notification preferences in the app. You acknowledge that opting out of receiving communications may impact your use of the Services.
      </p>

      <h2>4. IP Rights</h2>
      <h3>4.1. Ownership</h3>
      <p>
        You acknowledge that we or our suppliers own all right, title, and interest, including all intellectual property rights, in and to the Service. Except for the limited access rights expressly set forth in these Terms, you are not granted any rights with respect to the Service and no implied licenses are granted by Gravy.
      </p>
      <h3>4.2. Trademarks</h3>
      <p>
        Gravy and all related graphics, logos, service marks, and trade names used in connection with the Service are trademarks of Gravy and may not be used without permission in connection with any third-party products or services. Other trademarks that may appear on the Service are the property of their respective owners.
      </p>
      <h3>4.3. Feedback</h3>
      <p>
        We welcome feedback to help us build a better service. If you provide us with any feedback or suggestions regarding the Service (“Feedback”), you acknowledge that we may use such Feedback freely without any obligation to you. Feedback is not confidential or proprietary, so please do not include any information you consider confidential.
      </p>

      <h2>5. Third-Party Services</h2>
      <h3>5.1. Third-Party Materials</h3>
      <p>
        As part of the Service, you may have access to materials, links, applications, advertisements, and services that are hosted or controlled by a third party (collectively, “Third-Party Materials”). You agree that it is impossible for Gravy to monitor such materials and that you access them at your own risk. Gravy provides these materials as a convenience and does not review, approve, or endorse them. When you click on any Third-Party Material, the applicable third party’s terms and policies apply.
      </p>
      <p>
        You acknowledge and agree that Gravy may receive commissions when you access Third-Party Materials through the App.
      </p>
      <h3>5.2. Accessing and Downloading the Application from Apple App Store</h3>
      <p>
        (a) You acknowledge that these Terms are between you and Gravy only, and not with Apple, Inc. (“Apple”), and that Gravy, not Apple, is solely responsible for the Apple-Sourced App and its content. Your use of the Apple-Sourced App must comply with the App Store Terms of Service.
      </p>
      <p>
        (b) You acknowledge that Apple has no obligation to provide maintenance or support for the Apple-Sourced App.
      </p>
      <p>
        (c) In the event that the Apple-Sourced App fails to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price to you. To the maximum extent permitted by law, Apple will have no other warranty obligation with respect to the Apple-Sourced App.
      </p>
      <p>
        (d) You and Gravy acknowledge that Gravy, not Apple, is responsible for addressing any claims relating to the Apple-Sourced App or your use thereof.
      </p>
      <p>
        (e) You and Gravy acknowledge that, in the event of any third-party claim that the Apple-Sourced App or your use of it infringes any intellectual property rights, Gravy, not Apple, will be solely responsible for the investigation and defense of such claim.
      </p>
      <p>
        (f) You and Gravy acknowledge that Apple, and its subsidiaries, are third-party beneficiaries of these Terms as related to your license of the Apple-Sourced App, and that Apple has the right to enforce these Terms as a third-party beneficiary.
      </p>
      <p>
        (g) Without limiting any other terms, you must comply with all applicable third-party agreements when using the Apple-Sourced App.
      </p>
      <h3>5.3. Accessing and Downloading the Application from the Google Play Store or Google Chrome Web Store</h3>
      <p>
        For any App acquired from the Google Play Store or Google Chrome Web Store (“Google-Sourced App”): (i) these Terms are between you and Gravy only, not with Google or its affiliates; (ii) your use of the Google-Sourced App must comply with Google’s applicable terms of service; (iii) Google is only a provider of the store; (iv) Gravy, not Google, is solely responsible for the Google-Sourced App; (v) Google has no liability with respect to the Google-Sourced App or these Terms; and (vi) Google is a third-party beneficiary to these Terms as they relate to your license of the App.
      </p>

      <h2>6. Content</h2>
      <p>
        You acknowledge that all information, data, text, photographs, messages, tags, and other materials accessible through the Service (“Content”) are the sole responsibility of the party from whom such Content originated. You, and not Gravy, are entirely responsible for any Content you upload or otherwise make available through the Service (“Your Content”). By submitting Your Content, you grant Gravy a fully-paid, royalty-free, worldwide, non-exclusive license to use, sublicense, distribute, reproduce, modify, adapt, publicly perform, and display Your Content in any form or medium now known or later developed, for the full term of any intellectual property rights that may exist in Your Content. Except with respect to Your Content, you agree that you have no right, title, or interest in any Content on the Service.
      </p>
      <h3>6.1. Investigations</h3>
      <p>
        Gravy may, but is not obligated to, monitor or review the use of the Service and Content at any time. Without limiting the foregoing, Gravy may remove any of Your Content for any reason (or no reason) and may immediately terminate your license to use the Service if you are found to have violated these Terms.
      </p>
      <h3>6.2. No Obligation to Pre-Screen Content</h3>
      <p>
        Gravy may, but is not obligated to, pre-screen, refuse, or remove any Content for any reason, including if such Content violates these Terms or is otherwise objectionable. Gravy is not responsible for the deletion or accuracy of any Content. You are solely responsible for setting the appropriate access levels for Your Content.
      </p>
      <h3>6.3. Representations and Warranties</h3>
      <p>
        You represent and warrant that you have all necessary rights and permissions to provide, access, or submit any data, content, information, or feedback—including Your Content—to Gravy, and that you grant Gravy the rights and permissions provided herein with respect to such submissions.
      </p>

      <h2>7. User Conduct</h2>
      <p>
        As a condition of your use of the Service, you agree not to use the Service for any purpose prohibited by these Terms or applicable law. You shall not:
      </p>
      <ul>
        <li>Submit information that is false, inaccurate, tampered with, or manipulated to redeem a Reward;</li>
        <li>Link your Gravy Account to any bank account, credit/debit card, email account, retailer/digital consumer account, or other app that is not owned by you;</li>
        <li>Redeem, or attempt to redeem, Rewards for products that were not actually purchased or were returned;</li>
        <li>Infringe any patent, trademark, trade secret, copyright, or other right of any person or entity;</li>
        <li>Engage in any unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive, tortious, obscene, offensive, or profane conduct;</li>
        <li>Send unauthorized advertising, junk, or bulk e-mail;</li>
        <li>Engage in commercial activities or sales without Gravy’s prior written consent;</li>
        <li>Impersonate any person or entity, including any employee or representative of Gravy;</li>
        <li>Interfere with the proper functioning of the Service;</li>
        <li>Engage in any harmful acts directed against the Service, including violating security features, using automated means to access or crawl the Service, introducing harmful code, or otherwise disrupting other users’ access.</li>
      </ul>

      <h2>8. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless Gravy, its officers, employees, agents, partners, suppliers, and licensors (each, a “Gravy Party” and collectively, the “Gravy Parties”) from any loss, claim, or demand arising out of: (i) your use of the Service; (ii) your violation of these Terms; (iii) your violation of applicable laws or regulations; (iv) disputes between you and any third party; or (v) your violation of any rights of any third party. Gravy may assume the exclusive defense of any claim for which you are required to indemnify us, and you agree to cooperate fully with our defense. This section will survive any termination of your Account or these Terms.
      </p>

      <h2>9. DISCLAIMERS</h2>
      <p>
        NO WARRANTIES. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITH ALL FAULTS. Gravy and its affiliates, contractors, employees, agents, or third-party partners expressly disclaim all warranties—express, implied, or statutory—including any warranties of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.
      </p>
      <p>
        Gravy makes no warranty that the Service will meet your requirements, be uninterrupted, secure, or error-free, or that any content obtained through the Service is accurate or reliable. You assume all risk associated with your use of the Service.
      </p>

      <h2>10. LIMITATION ON LIABILITY</h2>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Gravy PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR LOST PROFITS, LOST DATA, PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY—even if advised of the possibility of such damages. Access to and use of the Service is at your sole risk.
      </p>
      <p>
        UNDER NO CIRCUMSTANCES WILL Gravy PARTIES BE LIABLE TO YOU FOR MORE THAN THE GREATER OF (A) THE TOTAL AMOUNT PAID TO GRAVY BY YOU DURING THE ONE-MONTH PERIOD PRIOR TO THE INCIDENT, OR (B) ONE HUNDRED U.S. DOLLARS (100 USD). These limitations do not apply to liability for death or personal injury caused by negligence or fraud.
      </p>

      <h2>11. Term and Termination</h2>
      <h3>11.1. Term</h3>
      <p>
        These Terms commence on the earlier of (a) the date you first used the Service or (b) the date you accepted these Terms and will remain in effect while you use the Service, unless terminated earlier as provided herein.
      </p>
      <h3>11.2. Termination of Services by Gravy</h3>
      <p>
        We may terminate these Terms at any time for any reason, including if you have materially breached any provision of these Terms, or if required by law. Gravy may immediately suspend or terminate any Services provided to you.
      </p>
      <h3>11.3. Termination of Services by You</h3>
      <p>
        If you wish to terminate these Terms, you may do so by closing your Account. Follow the instructions provided in the App to delete your Account.
      </p>
      <h3>11.4. Effect of Termination</h3>
      <p>
        Termination of these Terms includes removal of your access and all related information, files, Rewards, and Your Content associated with your Account. All rights to Rewards in your Account will terminate, and Gravy will not be liable for any suspension or termination, including deletion of Your Content and your Rewards. Provisions that by their nature should survive termination will remain in effect.
      </p>

      <h2>12. Dispute Resolution</h2>
      <h3>12.1. Applicability of Arbitration Agreement</h3>
      <p>
        You agree that any dispute, claim, or request for relief relating to your access or use of the Site, the Service, or your relationship with Gravy will be resolved by binding arbitration rather than in court, except that you may seek relief in small claims court if applicable, or equitable relief for intellectual property infringement.
      </p>
      <h3>12.2. Arbitration Rules and Forum</h3>
      <p>
        The Federal Arbitration Act governs this Arbitration Agreement. To begin an arbitration proceeding, send a written request to our registered agent at National Registered Agents, Inc., 1209 Orange Street, Wilmington, Delaware 19801. Disputes involving claims under $250,000 will be subject to JAMS’s Streamlined Arbitration Rules, while other disputes will follow JAMS’s Comprehensive Arbitration Rules. If you cannot afford JAMS’s fees, Gravy may pay them for disputes totaling less than $10,000.
      </p>
      <h3>12.3. Authority of Arbitrator</h3>
      <p>
        The arbitrator shall have exclusive authority to determine the scope, enforceability, and interpretation of this Arbitration Agreement and to resolve any related disputes. The arbitrator’s decision will be final and binding.
      </p>
      <h3>12.4. Waiver of Jury Trial</h3>
      <p>
        You and Gravy hereby waive any right to a trial by jury, agreeing instead to resolve disputes by individual arbitration.
      </p>
      <h3>12.5. Waiver of Class or Other Non-Individualized Relief</h3>
      <p>
        All disputes, claims, and requests for relief must be arbitrated on an individual basis and not on a class or collective basis.
      </p>
      <h3>12.6. 30-Day Right to Opt Out</h3>
      <p>
        You have the right to opt out of this Arbitration Agreement by sending written notice to <a href="mailto:privacy@joinGravy.com">privacy@joinGravy.com</a> within 30 days of first becoming subject to this Agreement. Your notice must include your name, address, the phone number used to set up your Account, and a clear statement of your intent to opt out.
      </p>
      <h3>12.7. Severability</h3>
      <p>
        If any part of this Arbitration Agreement is found to be invalid or unenforceable, that part will be severed, and the remainder will continue in full force and effect.
      </p>
      <h3>12.8. Survival of Agreement</h3>
      <p>
        This Arbitration Agreement will survive the termination of your relationship with Gravy.
      </p>
      <h3>12.9. Modification</h3>
      <p>
        If Gravy makes any material change to this Arbitration Agreement in the future, you may reject that change within 30 days by notifying Gravy at: Gravy Technologies, Inc.; 228 Park Ave S Suite 77949; New York, New York 10003.
      </p>

      <h2>13. General</h2>
      <h3>13.1. Changes</h3>
      <p>
        These Terms are subject to occasional revision, and we reserve the right to charge fees for accessing and using the Service. If we make substantial changes, we may require you to accept the changes by email and/or by posting notice on the Service. The changes will be effective upon acceptance, 30 days following email notice, or 30 days after posting, whichever occurs first. For new users, the changes are effective immediately.
      </p>
      <h3>13.2. Export</h3>
      <p>
        The Service may be subject to U.S. export control laws. You agree not to use, export, reexport, or transfer any U.S. technical data acquired from Gravy in violation of these laws.
      </p>
      <h3>13.3. Disclosures</h3>
      <p>
        If you are a California resident, you may report complaints to the Complaint Assistance Unit of the California Department of Consumer Affairs by contacting them at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
      </p>
      <h3>13.4. Electronic Communications</h3>
      <p>
        Communications between you and Gravy are conducted electronically. You consent to receive such communications in electronic form, and agree that these communications satisfy any legal requirement that they be in hard copy.
      </p>
      <h3>13.5. Force Majeure</h3>
      <p>
        Gravy shall not be liable for any delay or failure to perform resulting from causes beyond its reasonable control, including but not limited to acts of God, war, terrorism, riots, or other events.
      </p>
      <h3>13.6. Questions, Complaints, Claims</h3>
      <p>
        If you have any questions, complaints, or claims regarding the Service, please contact us at <a href="mailto:privacy@joinGravy.com">privacy@joinGravy.com</a>. We will do our best to address your concerns.
      </p>
      <h3>13.7. Taxes</h3>
      <p>
        You are solely responsible for complying with any tax obligations related to your use of the Service.
      </p>
      <h3>13.8. Governing Law and Venue</h3>
      <p>
        These Terms and any related actions will be governed by the laws of the state of New York, consistent with the Federal Arbitration Act, without regard to conflicts of law. All claims will be litigated exclusively in the state or federal courts located in New York, New York.
      </p>
      <h3>13.9. Notice</h3>
      <p>
        You are responsible for providing Gravy with your current email address. Notices sent to an invalid email address will still be considered effective. You may send notice to: Gravy Technologies, Inc.; 228 Park Ave S Suite 77949; New York, New York 10003.
      </p>
      <h3>13.10. International Users</h3>
      <p>
        The Service can be accessed from countries around the world and may contain references to services or content not available in your country. Gravy makes no representations that the Service is appropriate or available for use in other locations.
      </p>
      <h3>13.11. Entire Agreement</h3>
      <p>
        These Terms constitute the entire agreement between you and Gravy regarding your use of the Service. If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will continue in effect. These Terms, and your rights and obligations hereunder, may not be assigned by you without Gravy’s prior written consent; however, Gravy may assign these Terms at its discretion.
      </p>
      <h3>13.12. Contact Information</h3>
      <p>
        Tail Risk, Inc.<br />
        38 North 3rd Street<br />
        Philadelphia, PA 19106<br />
        <a href="mailto:help@gravyapp.com">help@gravyapp.com</a>
      </p>
    </div>
  );
};

export default TermsOfUse;
