import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Effective Date: February 24, 2025</h1>
      <p>
        Privacy and security are very important to us at Gravy. This End User Privacy Policy (“Policy”) is meant to help you (the “end user”) understand how we at Gravy collect, use, and share your data when you use Gravy products or services.
      </p>
      <p>
        This Policy applies to Gravy (collectively, “we,” “our,” and “us”). (Please see the “Contacting Gravy” section below for which of these entities is responsible for processing your data.)
      </p>
      <p>
        You should read this Policy carefully, it contains important information about your privacy rights and choices.
      </p>

      <h2>First, Some Background</h2>
      <p>
        Our mission at Gravy is to make saving on shopping fun and low effort. By delivering access to exciting, usable cash back rewards, we help consumers save money automatically at their favorite shops.
      </p>

      <h2>About this Policy</h2>
      <p>
        Our goal with this Policy is to provide a simple and straightforward explanation of what data Gravy collects from and about you and how we use and share that information. We value transparency and want to provide you with a clear and concise description of how we treat your data.
      </p>
      <p>
        Gravy's services are not directed to individuals under 18 and we do not knowingly collect data relating to children.
      </p>

      <h2>Our Data Practices</h2>
      <p>
        Gravy is committed to providing end users with meaningful control over their data. This section describes Gravy's data practices relating to our processing of data about you. We also provide summaries of our practices organized by category of data collected and by product at the end of this Policy in the Summaries of Processing Activities section.
      </p>

      <h2>Data We Collect and Categories of Sources</h2>
      <p>
        As explained in greater detail below, the data we collect, use, and share depends on the shops that you connect to through Gravy. Depending on which third party apps you connect to, Gravy may collect the following:
      </p>
      <ul>
        <li>Data from the electronic device you use to connect your accounts through Gravy;</li>
        <li>Data from our affiliates to provide support to you or to provide you with a better user experience; and/or</li>
        <li>Data from other sources, including service providers and identity verification and fraud prevention services.</li>
      </ul>

      <h2>Data you provide to us</h2>
      <p>
        When you use Gravy's products or services (like when you use Gravy to connect your shopping accounts) we collect the following data from you as needed to power your particular app or service:
      </p>
      <ul>
        <li>Identifiers like name, email address, and phone number;</li>
        <li>Login data when required by the provider of your account, like cookies, session, and localstorage;</li>
        <li>Any additional information needed to connect your accounts, including one-time password (OTP) and/or</li>
      </ul>
      <p>
        When you provide login data and additional information needed to connect your accounts, you also give Gravy permission and authority to act on your behalf to access and transmit data to and from your shopping apps.
      </p>

      <h2>Data we collect from third party shopping apps about and from your accounts</h2>
      <p>
        Depending on which Gravy products or services you use, we may collect the following data from your shopping apps:
      </p>
      <ul>
        <li>Data about an account balance, including current and available balance;</li>
        <li>Identifiers and data about the account owner(s), including name, email address, phone number, and address information;</li>
        <li>Data about account transactions, including amount, date, payee, type, quantity, price, location, and a description of the transaction;</li>
      </ul>

      <h2>Data we receive from your devices</h2>
      <p>
        When you use a device, like your smartphone, tablet, or computer, to interact with our services, we may collect the following data about that device:
      </p>
      <ul>
        <li>Internet protocol (IP) address;</li>
        <li>Timezone setting and location, device location;</li>
        <li>Hardware model and operating system;</li>
        <li>Browser data;</li>
        <li>Network data;</li>
      </ul>

      <h2>Data we receive about you from other sources</h2>
      <p>
        When needed to provide a service, identify your device, or to help prevent fraud, abuse, or security threats, we may also receive data about you directly from third parties, including your wireless carrier, agents and our service providers.
      </p>

      <h2>How We Use Your Data</h2>
      <p>We use your data for the following business and commercial purposes:</p>
      <ul>
        <li>
          <strong>Provide Services:</strong> To operate, provide, and maintain our services.
        </li>
        <li>
          <strong>Develop Existing Services:</strong> To improve, enhance, modify, add to, and further develop our services.
        </li>
        <li>
          <strong>Help Prevent Fraud, Verify Your Identity, or Protect Privacy:</strong> To verify your identity and help protect you, our partners, Gravy, and others from fraud, malicious activity, and other privacy and security-related concerns.
        </li>
        <li>
          <strong>Develop New Services:</strong> To develop new products and services.
        </li>
        <li>
          <strong>Provide Support:</strong> To provide support to you or to developers, including to help respond to your inquiries related to our services or developers’ apps.
        </li>
        <li>
          <strong>Communicate With You:</strong> To communicate with you and send you things like technical notices, updates, security alerts, and messages.
        </li>
        <li>
          <strong>Investigate Misuse and Misconduct:</strong> To investigate any misuse of our service or developers’ apps, including violations of our Developer Policy, criminal activity, or other unauthorized access to our services.
        </li>
        <li>
          <strong>For Legal Purposes:</strong> To comply with contractual and legal obligations under applicable law and for other legal purposes such as to establish and defend against claims.
        </li>
        <li>
          <strong>With Your Consent:</strong> For other notified purposes with your consent or at your direction.
        </li>
      </ul>
      <p>
        We collect, use, and share data that has been aggregated or anonymized in a manner that does not identify you personally for any purpose permitted under applicable law. This includes creating or using aggregated or anonymized data to develop new services, or to facilitate research, and for analytics purposes to help assess the speed, accuracy, and/or security of our services.
      </p>

      <h2>Our Lawful Bases for Processing (EEA and UK End Users)</h2>
      <p>
        For individuals in the European Economic Area (“EEA”) or the United Kingdom (“UK”), Gravy only processes your personal data when we have a valid legal basis to do so. Our legal basis for processing the data we collect will depend on what data we collected and the purpose for processing it. Generally, we will only collect and process your data where:
      </p>
      <ul>
        <li>
          We are bound by any contract or agreement with you (for example, to comply with our end user services agreements) and must adhere to various legal requirements when offering regulated services, including our account information and payment services.
        </li>
        <li>
          We require your data to comply with our legal obligations under applicable law, to safeguard Gravy's legal rights, and prevent and identify criminal activities such as money laundering and fraud. For these purposes, Gravy may find it necessary to share your personal data with entities such as courts, law enforcement agencies, and providers of anti-money laundering services;
        </li>
        <li>
          Processing is necessary for our legitimate interests to effectively maintain the integrity of our services. This includes retrieving account information or providing payment initiation services (as well as verifying the output of this activity), engaging in communication with you, and ensuring that Gravy, as a regulated entity, upholds the expected standards or you have given your consent to do so.
        </li>
      </ul>
      <p>
        To the extent we rely on consent to collect and process your data, you have the right to withdraw your consent at any time per the instructions provided in this Policy.
      </p>

      <h2>How We Share Your Data</h2>
      <p>We share your data for the following reasons:</p>
      <ul>
        <li>To enforce any contract with you;</li>
        <li>
          With our data processors and other service providers, partners, agents or contractors in connection with the services they perform for us;
        </li>
        <li>
          With your shopping apps to help establish, maintain, or manage a connection you’ve chosen to make with Gravy;
        </li>
        <li>
          If we believe in good faith that disclosure is appropriate to comply with applicable law, regulation, or legal process (like a court order or subpoena);
        </li>
        <li>
          In connection with a change in ownership or control of all or a part of our business (like a merger, acquisition, reorganization, or bankruptcy);
        </li>
        <li>
          Between and among Gravy and our current and future parents, affiliates, subsidiaries and other companies under common control or ownership;
        </li>
        <li>
          As we believe reasonably appropriate to prevent malicious or fraudulent activities, or otherwise protect the rights, privacy, safety, or property of you, developers, our partners, Gravy, and others; or
        </li>
        <li>
          For any other notified purpose with your consent or at your direction.
        </li>
      </ul>
      <p>
        (For US users) We do not share your data with non-affiliated third parties except as permitted by law (as authorized by 12 C.F.R. § 1016.14 and 1016.15).
      </p>
      <p>
        We only share your personal financial data with third parties to power the services you requested, when you consent, and/or to protect against fraud.
      </p>
      <p>
        We may collect and share cookie data with third parties when you visit our website, or we may allow third parties to collect this cookie data from our sites. Please see our Cookie Policy and All Audience Privacy Statement for more details.
      </p>

      <h2>Our Retention and Deletion Practices</h2>
      <p>
        We retain your data only as long as it is needed. To determine whether the data is needed, we consider the reason your data was collected and used and any legal requirements to hold onto your data. We review your data periodically to ensure it is still needed to fulfill the purpose for which it was collected or any other legal requirements. If a developer removes your connection from their app to your data, Gravy systems are designed to automatically delete your personal data, subject to certain exceptions where we may still retain your information.
      </p>
      <p>Your data will only be processed as required by law or in accordance with this Policy.</p>
      <p>
        Please refer to the “Your Data Protection Rights” section of this Policy for options that may be available to you, including how to request deletion of your data. The “Your Privacy Controls” section of this Policy also provides details about tools available to you to help you view and manage the connections you’ve made using Gravy. You can also contact us about our data retention practices using the contact information in the “Contacting Gravy” section below.
      </p>

      <h2>Protection of Data</h2>
      <p>
        Gravy security policies and practices are designed to protect the confidentiality and integrity of your data. Gravy implements controls designed to limit access to this data to personnel who have a business reason to know it and prohibits its personnel from unlawfully accessing, using or disclosing this data.
      </p>

      <h2>Your Data Protection Rights</h2>
      <p>
        Regardless of where you live, we will honor the following rights related to your personal data, subject to some limitations and exceptions provided by law, and you will not be discriminated against for exercising them:
      </p>
      <ul>
        <li>Access data collected about you;</li>
        <li>
          Request access to more details about the categories and specific pieces of personal information we may have collected about you in the last 12 months (including personal information disclosed for business purposes);
        </li>
        <li>Request, under certain circumstances, that we rectify or update your data that is inaccurate or incomplete;</li>
        <li>Request, under certain circumstances, that we erase or restrict the processing of your data;</li>
        <li>Object to our processing of your data under certain conditions provided by law;</li>
        <li>Where processing of your data is based on consent, withdraw that consent;</li>
        <li>
          Request that we provide data collected about you in a structured, commonly used and machine-readable format so that you can transfer it to another company, where technically feasible.
        </li>
      </ul>
      <p>
        Please note that for an official record of your financial information you should make that request directly to your financial institution.
      </p>
      <p>
        Depending on where you live, you may have the right to lodge a complaint. We welcome and appreciate the chance to address any concerns you may have and encourage you to contact us. In addition, and depending on your jurisdiction, you may have the right to make a complaint at any time to your (data protection) supervisory authority. For end users in the EEA, you can find contact information for the European Data Protection Board (EDPB) on the EDPB’s website here. For end users in the UK, you can find contact information for the Information Commissioner’s Office (ICO) on the ICO’s website here. For end users in Switzerland this is the Federal Data Protection and Information Commissioner which you can find here.
      </p>
      <p>
        To exercise any rights you have, you can submit a request directly to <a href="mailto:help@gravyapp.com">help@gravyapp.com</a>. You may be required to provide additional information necessary to confirm your identity before we can respond to your request.
      </p>
      <p>
        If we receive your request from an authorized agent, we may ask for evidence that the agent has valid written authority, like a power of attorney, to submit requests on your behalf.
      </p>
      <p>
        We will consider requests and provide our response within a reasonable period of time (and within any time period required by applicable law). Please note, however, that certain data may be exempt from such requests, for example if we need to keep the data to comply with our own legal obligations or to establish, exercise, or defend legal claims.
      </p>
      <p>
        We have appointed a data protection officer (DPO) who is also responsible for overseeing questions in relation to this Policy and can be contacted via email at <a href="mailto:help@gravyapp.com">help@gravyapp.com</a>.
      </p>

      <h2>Promotional Communications</h2>
      <p>
        You can opt out of receiving email and in-app push notifications containing promotional messages from us by following the instructions on screen or by following the instructions in those messages. If you decide to opt out, we may still send you non-promotional communications in-app, or via email and text, such as transaction receipts and messages about your account, or our processing of your information in accordance with the provision of our Services.
      </p>

      <h2>Changes To This Policy</h2>
      <p>
        We update or change this Policy from time to time. If we make any updates or changes, we will post the new policy on Gravy's website and update the effective date at the top of this Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
